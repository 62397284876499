import { find, isEmpty, map } from "lodash-es";
import { useSvgIcon } from "@/hooks/common/icon";
import { $t } from "@/locales";
import { router } from "@/router";

interface CustomMenu {
  name?: string;
  key?: string;
  title?: string;
  icon?: string;
  i18nKey?: string;
  sort?: number;
  children?: CustomMenu[];
}

const menus = [
  { name: "home" },
  // {
  //   key: "document",
  //   title: "文档",
  //   icon: "icons8:document",
  //   i18nKey: "route.document",
  //   children: [
  //     { name: "document_multiple_scb" },
  //     { name: "document_podo" },
  //     { name: "document_bk" },
  //   ],
  // },
  {
    key: "music",
    title: "赞美网",
    icon: "la:music",
    i18nKey: "route.music",
    children: [{ name: "music_setting" }, { name: "music_version" }],
  },
  { name: "about" },
];

export const getGlobalMenus = (): App.Global.Menu[] => {
  const { SvgIconVNode } = useSvgIcon();
  const generateMenus = (configs: CustomMenu[]): App.Global.Menu[] => {
    return map(configs, (menu) => {
      const {
        name,
        children,
        title: mt,
        icon: mi,
        i18nKey: mi18,
        ...args
      } = menu || {};
      const { meta, path } = (name &&
        find(router.getRoutes(), (route) => route.name === name)) || {
        meta: { title: mt, icon: mi, i18nKey: mi18 },
      };
      // @ts-ignore
      const { key, title, i18nKey, icon, localIcon, iconFontSize } = meta || {};
      const label = i18nKey ? $t(i18nKey as App.I18n.I18nKey) : title!;
      const baseMenu = {
        label,
        key: name || key,
        routeKey: name,
        routePath: path,
        icon: SvgIconVNode({ icon, localIcon, fontSize: iconFontSize || 20 }),
        i18nKey,
        ...args,
      } as App.Global.Menu;
      return isEmpty(children)
        ? baseMenu
        : { ...baseMenu, children: generateMenus(children!) };
    }).filter(Boolean) as App.Global.Menu[];
  };
  return generateMenus(menus);
};
