import type { CustomRecordRaw } from "@/router";

export const MusicRoutes: CustomRecordRaw[] = [
  {
    name: "music_setting",
    path: "/music/setting",
    component: "music/setting/index",
    meta: {
      title: "设置",
      i18nKey: "route.music_setting",
      icon: "uil:setting",
    },
  },
  {
    name: "music_version",
    path: "/music/version",
    component: "music/version/index",
    meta: {
      title: "版本管理",
      i18nKey: "route.music_version",
      icon: "qlementine-icons:version-control-16",
    },
  },
];
