<script setup lang="ts">
import { useUserStore } from "@/store/modules/user";

defineOptions({
  name: "ToggleEnvButton",
});

const userStore = useUserStore();

const showToggleEnv = () => {
  userStore.setEnvVisible(true);
};
</script>

<template>
  <ButtonIcon
    icon="eos-icons:env"
    tooltip-content="切换环境"
    @click="showToggleEnv"
  />
</template>

<style scoped></style>
